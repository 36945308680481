import SocialMediaIcons from "../Components/SocialMediaIcons";

const Footer = () => {
	return (
		<footer className="flex-col items-center justify-center py-8 font-mono relative">
			<div className="flex flex-col items-center justify-center">
				<p className="mb-4 text-green-200">
					&copy; Designed and Built By Ben Arlanda
				</p>
				<p className="mb-4 text-green-200 text-xs">
					Created with React | Tailwind
				</p>
				<SocialMediaIcons />
			</div>
		</footer>
	);
};

export default Footer;
